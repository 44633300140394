import React, { Component } from 'react'
import Menu from '../components/menu'
import Helmet from 'react-helmet'
import './navigation.css'



class Navigation extends Component {

  constructor(props) {
    super(props)

    this.state = {
      isMenuOpen: false,
    }
  }

  toggleMenu = () => {
    this.setState({
      isMenuOpen: !this.state.isMenuOpen
    })
  }

  handleMenu() {
    if(this.state.isMenuOpen) {
      this.toggleMenu()
    }
    this.ticking = false
  }

  handleScroll = () => {
    if (!this.ticking) {
      this.ticking = true
      requestAnimationFrame(() => this.handleMenu())
    }
  }

  componentDidMount() {
    window.addEventListener(`scroll`, this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener(`scroll`, this.handleScroll)
  }


  render() {
    return (
      <div>
        <Helmet>
          <html
            lang="ko"
            className={this.state.isMenuOpen ? 'no-scroll' : ''}
          />
          <body
            className={this.state.isMenuOpen ? 'no-scroll' : ''}
          />
        </Helmet>

        <div className='navigation'>
          <div
            className={this.state.isMenuOpen ? 'hamburger open' : 'hamburger'}
            onClick={this.toggleMenu}
          >
            <span className="hamburger__top-bun"></span>
            <span className="hamburger__bottom-bun"></span>
          </div>
        </div>
        <Menu
          isMenuOpen={this.state.isMenuOpen}
          toggleMenu={this.toggleMenu}
        />
      </div>
    );
  }
}

export default Navigation
