import React from 'react'
import { Link } from 'gatsby'
import LogoMain from './logo-main'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import './menu.css'

import logo1 from '../static/svgs/logo-activity-1.svg'
import logo2 from '../static/svgs/logo-activity-2.svg'
import logo3 from '../static/svgs/logo-activity-3.svg'
import logo4 from '../static/svgs/logo-activity-4.svg'
import logo5 from '../static/svgs/logo-activity-5.svg'
import logoText1 from '../static/svgs/logo-activity-1-text.svg'
import logoText2 from '../static/svgs/logo-activity-2-text.svg'
import logoText3 from '../static/svgs/logo-activity-3-text.svg'
import logoText4 from '../static/svgs/logo-activity-4-text.svg'
import logoText5 from '../static/svgs/logo-activity-5-text.svg'



const Menu = ({ isMenuOpen, toggleMenu }) => {
  return (
    <div>
      <div
        className={isMenuOpen ? 'menu-overlay menu-overlay-opened' : 'menu-overlay' }
        onClick={toggleMenu}
      >
      </div>
      <div
        className={isMenuOpen ? 'menu-container menu-container-opened' : 'menu-container' }
      >
        <div className='menu-inner-container'>
          <LogoMain
            isMenuOpen={isMenuOpen}
            toggleMenu={toggleMenu}
          />

          <div className='menu-activities'>
            <div className="menu-item menu-group">
              오프라인 밋업 & 컨퍼런스
              <div className="menu-group-line"></div>
            </div>
            <div className="menu-item menu-group">
              오디오 팟캐스트 & 인터뷰 영상
              <div className="menu-group-line"></div>
            </div>
            <div className="menu-item menu-group">
              디자인 & 아티클 셀렉션
              <div className="menu-group-line"></div>
            </div>
            <Link to='/spectrumday/' onClick={toggleMenu}>
              <div className="menu-item menu-activity">
                <div className='menu-activity-logo-inner-container'>
                  <img src={logo1} alt="logo"/>
                </div>
                <div className="menu-activity-logo-text-inner-container">
                  <img src={logoText1} alt="logo" />
                </div>
              </div>
            </Link>
            <Link to='/spectrumcon/' onClick={toggleMenu}>
              <div className="menu-item menu-activity">
                <div className='menu-activity-logo-inner-container'>
                  <img src={logo2} alt="logo"/>
                </div>
                <div className="menu-activity-logo-text-inner-container">
                  <img src={logoText2} alt="logo" />
                </div>
              </div>
            </Link>
            <Link to='/designtable/' onClick={toggleMenu}>
              <div className="menu-item menu-activity">
                <div className='menu-activity-logo-inner-container'>
                  <img src={logo3} alt="logo"/>
                </div>
                <div className="menu-activity-logo-text-inner-container">
                  <img src={logoText3} alt="logo" />
                </div>
              </div>
            </Link>
            <Link to='/designbox/' onClick={toggleMenu}>
            <div className="menu-item menu-activity">
              <div className='menu-activity-logo-inner-container'>
                <img src={logo4} alt="logo"/>
              </div>
              <div className="menu-activity-logo-text-inner-container">
                <img src={logoText4} alt="logo" />
              </div>
            </div>
            </Link>
            <Link to='/spectrumpicks/' onClick={toggleMenu}>
              <div className="menu-item menu-activity">
                <div className='menu-activity-logo-inner-container'>
                  <img src={logo5} alt="logo"/>
                </div>
                <div className="menu-activity-logo-text-inner-container">
                  <img src={logoText5} alt="logo" />
                </div>
              </div>
            </Link>
          </div>

          <div></div>

        </div>
      </div>
    </div>
  );
}

export default Menu
