import React from 'react'
import PropTypes from 'prop-types'
// import { StaticQuery, graphql } from 'gatsby'
import Navigation from './navigation'

import './base.css'
// import './normalize.css'



const Layout = ({ children, data }) => (

  // <StaticQuery
  //   query={graphql`
  //     query {
  //       allContentfulActivityPage (
  //         sort: { fields: [activityNumber], order: ASC }
  //       ) {
  //         edges {
  //           node {
  //             logo {
  //               fluid(maxWidth: 80, maxHeight: 80) {
  //                 ...GatsbyContentfulFluid
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }
  //   `}
  //   render={data => (
  //     <>
  //       <div className='container'>
  //         <Navigation
  //           data={data}
  //         />
  //         {children}
  //       </div>
  //     </>
  //   )}
  // />

  <div className='container'>
    {/* <Navigation /> */}
    {children}
  </div>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
