import React from 'react'
import { Link } from 'gatsby'
import './logo-main.css'
// import { isMobile } from 'react-device-detect'

import logo from '../static/svgs/logo-designspectrum.svg'
import logoText from '../static/svgs/logo-designspectrum-text.svg'


const LogoMain = ({ isMenuOpen, toggleMenu }) => {
  return (
    <div className='logo-main-container'>
      <div className={isMenuOpen ? "logo-main-menu" : "logo-main"}>
        <Link to='/' onClick={toggleMenu}>
          <img src={logo} alt="logo"/>
        </Link>
      </div>
      <div className={isMenuOpen ? "logo-main-text-menu" : "logo-main-text"}>
        <Link to='/' onClick={toggleMenu}>
          <img src={logoText} alt="logo"/>
        </Link>
      </div>
    </div>
  )
}

export default LogoMain
